<template>
  <div class="about">
    <div class="max-w-5xl max-h-screen bg-white overflow-auto mx-auto mb-4 p-8 text-leftleading">
      <h2 class="text-2xl text-brand uppercase mb-2">{{ lists.key }}</h2>
      <span v-html="lists.value" class="mb-2"></span>
    </div>
  </div>
</template>
<script>
export default {
    created(){
      this.$store.dispatch('legally_binding_contract/DataListDetails');
   },
    computed:{
      lists(){
         return this.$store.state.legally_binding_contract.listDetails.about || '';
      }
    }
}
</script>
